import React, { useCallback, useContext, useMemo, useState } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { QuizThemesContext } from "../../providers/QuizzesProvider";
import { stringToColor } from "../../utils/stringToColor";
import { IQuizTheme } from "../../types";
import { Text } from "../_base/Text/Text";
import { QuizzesList } from "../QuizzesList/QuizzesList";
import { ListLayout } from "../ListLayout/ListLayout";
import styles from "./CategoriesList.module.scss";

export const CategoriesList: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const { list: themesList, isFetching } = useContext(QuizThemesContext);

  const [currentTheme, setCurrentTheme] = useState<IQuizTheme | null>(null);

  const onCloseList = useCallback(() => {
    setCurrentTheme(null);
  }, []);

  return (
    <div className={styles.CategoriesList}>
      <Text
        fontSize={24}
        fontFamily="Yanone Kaffeesatz"
        color="white"
        textAlign="left"
      >
        {t("topCategories")}
      </Text>
      <div className={styles.CategoriesList__Content}>
        {isFetching && (
          <>
            <div className={cn(styles.Category, styles.Category_View_Stub)} />
            <div className={cn(styles.Category, styles.Category_View_Stub)} />
            <div className={cn(styles.Category, styles.Category_View_Stub)} />
          </>
        )}
        {themesList.map((theme, i) => (
          <Category
            key={i}
            title={theme.theme}
            onClick={() => {
              setCurrentTheme(theme);
            }}
          />
        ))}
      </div>
      {currentTheme && (
        <ListLayout
          position="fixed"
          title={currentTheme.theme}
          zIndex={2}
          onBack={onCloseList}
        >
          <QuizzesList
            items={currentTheme.quizzes}
            isShowSharingButton={false}
          />
        </ListLayout>
      )}
    </div>
  );
});

CategoriesList.displayName = "CategoriesList";

const Category: React.FC<{
  title: string;
  onClick: () => void;
}> = React.memo(({ title, onClick }) => {
  const color = useMemo<string>(() => {
    return stringToColor(title);
  }, [title]);

  return (
    <button
      className={styles.Category}
      style={{
        backgroundColor: color,
      }}
      onClick={onClick}
    >
      <Text fontSize={16} color="#eee">
        {title}
      </Text>
    </button>
  );
});
