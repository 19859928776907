import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { performAuth } from "../../firebaseClient";
import { Button } from "../../components/_base/Button/Button";
import { WrappedLoader } from "../../components/_base/Loader/Loader";
import { PATH } from "../../routes";
import { UserContext } from "../../providers/UserProvider";
import useUpdateEffect from "../../hooks/useUpdateEffect";
import styles from "./AuthScreen.module.scss";

interface IPropTypes {
  title: string;
  imgSrc: string;
  onSuccess: () => void;
}

const AuthScreen: React.FC<IPropTypes> = React.memo(
  ({ title, imgSrc, onSuccess }) => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const { ownUser } = useContext(UserContext);

    const [isFetching, setIsFetching] = useState(false);

    const onLogin = useCallback(() => {
      // there is request for user data just after authentication
      performAuth().then(() => setIsFetching(true));
    }, []);

    useUpdateEffect(() => {
      // handle authentication according to user data (lang, difficulty)
      if (ownUser.data && isFetching) {
        const { difficulty, lang } = ownUser.data;

        if (difficulty === null || lang === null) {
          navigate(PATH.profileSettings);
        }

        onSuccess();
        setIsFetching(false);
      }
    }, [ownUser]);

    if (isFetching) {
      return (
        <div className={styles.AuthScreen}>
          <WrappedLoader />
        </div>
      );
    }

    return (
      <div className={styles.AuthScreen}>
        <img className={styles.AuthScreen__Image} alt="sign-in" src={imgSrc} />
        <div className={styles.AuthScreen__Title}>{title}</div>
        <div className={styles.AuthScreen__Description}>
          {t("authDescription")}
        </div>
        <Button
          label={t("continueWithGoogle")}
          bgColor="#fff"
          fontColor="#232531"
          fontSize="24rem"
          onClick={onLogin}
        />
      </div>
    );
  }
);

AuthScreen.displayName = "AuthScreen";

export default AuthScreen;
