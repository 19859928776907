import cn from "classnames";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar } from "../../components/_base/Avatar/Avatar";
import { FlexSpacer } from "../../components/_base/Flex/Flex";
import { ListLayout } from "../../components/ListLayout/ListLayout";
import styles from "./LeaderBoardScreen.module.scss";
import { IQuizUserResult, IUserTotalScores } from "../../types";
import { UserContext } from "../../providers/UserProvider";
import { getUsersScore } from "../../firebaseClient";

export const QuizLeaderBoardScreen: React.FC<{
  quizTitle: string;
  quizResults: IQuizUserResult[];
  friendId: string | undefined;
  onClose: () => void;
}> = React.memo(({ quizTitle, quizResults, friendId, onClose }) => {
  const { ownUser } = useContext(UserContext);

  return (
    <LeaderBoard
      listPosition="fixed"
      myUid={ownUser?.data?.userId || ""}
      users={quizResults}
      quizTitle={quizTitle}
      friendId={friendId}
      onClose={onClose}
    />
  );
});

QuizLeaderBoardScreen.displayName = "QuizLeaderBoardScreen";

export const GlobalLeaderBoardScreen: React.FC = React.memo(() => {
  const { ownUser } = useContext(UserContext);
  const [globalResults, setGlobalResults] = useState<IUserTotalScores[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getUsersScore()
      .then(setGlobalResults)
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <LeaderBoard
      listPosition="static"
      myUid={ownUser?.data?.userId || ""}
      users={globalResults}
      isLoading={isLoading}
    />
  );
});

GlobalLeaderBoardScreen.displayName = "GlobalLeaderBoardScreen";

const PlAYERS_STUB = Array(10)
  .fill(0)
  .map((_, i) => i);

const LeaderBoard: React.FC<{
  users: IQuizUserResult[] | IUserTotalScores[];
  myUid: string;
  listPosition: "fixed" | "static";
  quizTitle?: string;
  friendId?: string;
  isLoading?: boolean;
  onClose?: () => void;
}> = React.memo(
  ({
    users: quizResults,
    myUid,
    listPosition,
    quizTitle,
    friendId,
    isLoading,
    onClose,
  }) => {
    const { t } = useTranslation();

    const [iIndex, friendIndex] = useMemo<[number, number]>(() => {
      let fInd = -1;
      let myInd = -1;
      if (friendId) {
        fInd = quizResults.findIndex(({ userId }) => userId === friendId);
      }
      if (myUid) {
        myInd = quizResults.findIndex(({ userId }) => userId === myUid);
      }

      return [myInd, fInd];
    }, [friendId, quizResults, myUid]);

    return (
      <ListLayout
        position={listPosition}
        isAddBottomSpace={false}
        isShowNavBar={true}
        onBack={onClose}
      >
        <div className={styles.LeaderBoardScreen__HeaderText}>
          {t("leaderboard")}
        </div>
        {quizResults && (
          <div className={styles.LeaderBoardScreen__QuizTitle}>{quizTitle}</div>
        )}
        <div className={styles.LeaderBoardScreen__List}>
          {quizResults.map((player, i) => {
            let type: "i" | "friend" | undefined;
            let isTopSticky: boolean | undefined;

            if (i === iIndex) {
              type = "i";
              isTopSticky = iIndex < friendIndex;
            } else if (i === friendIndex) {
              type = "friend";
              isTopSticky = friendIndex < iIndex;
            }

            return (
              <Player
                key={i}
                index={i + 1}
                name={player.userName}
                scores={"score" in player ? player.score : player.totalScore}
                avatar={player.photoURL}
                type={type}
                isTopSticky={isTopSticky}
              />
            );
          })}
          {isLoading &&
            PlAYERS_STUB.map((index) => (
              <Player
                key={index}
                index={index}
                type="stub"
                name=""
                avatar=""
                scores={0}
              />
            ))}
        </div>
      </ListLayout>
    );
  }
);

const Player: React.FC<{
  type: undefined | "i" | "friend" | "stub";
  index: number;
  name: string;
  avatar: string;
  scores: number;
  isTopSticky?: boolean;
}> = React.memo(({ type, index, name, avatar, scores, isTopSticky }) => {
  const { t } = useTranslation();

  return (
    <div
      className={cn(styles.Player, {
        [styles.Player_Sticky]: type !== undefined && type !== "stub",
        [styles.Player_Sticky_Top]: isTopSticky,
        [styles.Player_Type_Friend]: type === "friend",
        [styles.Player_Type_I]: type === "i",
        [styles.Player_Type_Stub]: type === "stub",
      })}
    >
      <div
        className={cn(styles.Player__Index, {
          [styles.Player__Index_Yellow]: index <= 3,
        })}
      >
        {index}
      </div>
      <Avatar size={40} photoURL={avatar} />
      <div className={styles.Player__NameContainer}>
        <div className={styles.Player__Name}>{name}</div>
        {type === "friend" && (
          <div className={styles.Player__FriendLabel}>{t("yourFriend")}</div>
        )}
      </div>
      <FlexSpacer />
      <div className={styles.Player__Scores}>{scores}</div>
    </div>
  );
});
