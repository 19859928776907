import i18n, { Resource, TFunction } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./locales/en_US/translation.json";
import uk from "./locales/uk_UA/translation.json";
import ru from "./locales/ru/translation.json";
import { Storage } from "../utils/storage";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    resources: {
      en: { translation: en },
      uk: { translation: uk },
      ru: { translation: ru },
    },
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "querystring", "navigator"],
      caches: [],
    },
    debug: process.env.NODE_ENV === "development",
  });

export default i18n;

export function setLanguage(lng: string) {
  Storage.setItem(window.localStorage, "i18nextLng", lng);
  return i18n.changeLanguage(lng);
}

export function getLangsOptions(t: TFunction, i18nResource: Resource) {
  return Object.keys(i18nResource).map((l) => ({ value: l, label: t(l) }));
}
