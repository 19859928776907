import cn from "classnames";
import React, { createContext, useCallback, useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PATH } from "../../routes";
import { HomeIcon, LeaderIcon, ProfileIcon } from "../_base/Icons/Icons";
import { UserContext } from "../../providers/UserProvider";
import styles from "./NavBar.module.scss";

export const NavBar: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const { customNavigationCheck } = useContext(NavBarCustomFuncsContext);
  const { fbUser } = useContext(UserContext);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onNavigate = useCallback(
    (path: string) => {
      const isOk = customNavigationCheck?.(path, pathname) ?? true;
      if (isOk) {
        navigate(path);
      }
    },
    [navigate, pathname, customNavigationCheck]
  );

  return fbUser ? (
    <div className={styles.NavBar}>
      <NavButton
        label={t("main")}
        currentPath={pathname}
        path={PATH.root}
        icon={<HomeIcon />}
        onClick={onNavigate}
      />
      <NavButton
        label={t("leaders")}
        currentPath={pathname}
        path={PATH.leaders}
        icon={<LeaderIcon />}
        onClick={onNavigate}
      />
      <NavButton
        label={t("profile")}
        currentPath={pathname}
        path={PATH.profile}
        icon={<ProfileIcon />}
        onClick={onNavigate}
      />
    </div>
  ) : null;
});

NavBar.displayName = "NavBar";

const NavButton: React.FC<{
  icon: React.ReactNode;
  label: string;
  currentPath: string;
  path: string;
  onClick: (path: string) => void;
}> = React.memo(({ icon, label, currentPath, path, onClick }) => {
  const { customIsSelected } = useContext(NavBarCustomFuncsContext);

  const isSelected = useMemo<boolean>(() => {
    let result = customIsSelected?.(path, currentPath);
    if (result === undefined) {
      result = path === currentPath;
    }
    return result;
  }, [path, currentPath, customIsSelected]);

  const _onClick = useCallback(() => {
    onClick(path);
  }, [path, onClick]);

  return (
    <button
      className={cn(styles.NavBar__NavButton, {
        [styles.NavBar__NavButton_IsSelected]: isSelected,
      })}
      onClick={isSelected ? undefined : _onClick}
    >
      {icon}
      <span>{label}</span>
    </button>
  );
});

export type CustomNavButtonIsSelectedFunc = (
  buttonPath: string,
  currentPath: string
) => boolean | undefined;
export type CustomNavBarNavigationCheck = (
  targetPath: string,
  currentPath: string
) => boolean | undefined;

export interface INavBarCustomFuncsContextValue {
  customIsSelected?: CustomNavButtonIsSelectedFunc;
  customNavigationCheck?: CustomNavBarNavigationCheck;
}

export const NavBarCustomFuncsContext =
  createContext<INavBarCustomFuncsContextValue>({});
