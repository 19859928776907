import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";
import { Loader, WrappedLoader } from "../Loader/Loader";
import styles from "./Button.module.scss";

interface IPropTypes {
  children?: React.ReactNode;
  type?: "filled" | "outlined";
  label?: string;
  bgColor?: string;
  fontColor?: string;
  fontSize?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  extraClass?: string;
  loaderType?: "sized" | "wrapped";
  onClick?: (event: React.MouseEvent) => void;
}

export const Button: React.FC<IPropTypes> = React.memo(
  ({
    children,
    type = "filled",
    label,
    bgColor = "#FEDE67",
    fontColor = "#121212",
    fontSize,
    isLoading,
    isDisabled,
    extraClass,
    loaderType = "wrapped",
    onClick,
  }) => {
    return (
      <button
        className={cn(
          styles.Button,
          {
            [styles.Button_IsLoading]: isLoading,
            [styles.Button_IsDisabled]: isDisabled,
          },
          extraClass
        )}
        style={{
          backgroundColor: type === "filled" ? bgColor : undefined,
          border: type === "outlined" ? `2rem solid ${bgColor}` : undefined,
          fontSize,
          color: isLoading
            ? "transparent"
            : type === "outlined"
            ? bgColor
            : fontColor,
        }}
        onClick={onClick}
      >
        {label}
        {children}
        {isLoading ? (
          loaderType === "wrapped" ? (
            <WrappedLoader color={fontColor} size={20} borderSize={5} />
          ) : (
            <Loader color={fontColor} size={20} borderSize={5} />
          )
        ) : null}
      </button>
    );
  }
);
Button.displayName = "Button";

export const ShareButton: React.FC<{ url: string; color?: string }> =
  React.memo(({ url, color }) => {
    return (
      <Button
        extraClass={styles.ShareButton}
        bgColor={color}
        onClick={(event) => {
          event.preventDefault();
          if (typeof navigator.share === "function") {
            navigator.share({ url });
          } else {
            navigator.clipboard.writeText(url);
          }
        }}
      >
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.8726 17.4596L20.6405 12.1943C20.8682 11.9916 21.0002 11.6883 21.0002 11.368C21.0002 11.0478 20.8682 10.7445 20.6405 10.5418L14.8726 5.27648C14.565 4.99005 14.1343 4.91983 13.7631 5.09559C13.392 5.27135 13.1454 5.6623 13.1279 6.10269V8.17942C5.0302 6.65163 3 14.5441 3 19C4.87848 15.6055 9.74634 9.45235 13.1279 14.5441V16.6264C13.143 17.068 13.3887 17.4614 13.7604 17.6388C14.132 17.8163 14.5641 17.7467 14.8726 17.4596Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Button>
    );
  });

export const BackButton: React.FC<{ onClick: () => void }> = React.memo(
  ({ onClick }) => {
    const { t } = useTranslation();

    return (
      <button className={styles.BackButton} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <path
            d="M15 19L8 12L15 5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        {t("back")}
      </button>
    );
  }
);

export const CloseButton: React.FC<{ onClick: () => void }> = React.memo(
  ({ onClick }) => {
    return (
      <button className={styles.CloseButton} onClick={onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
          <path
            d="M14 14L2 2"
            stroke="white"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M14 2L2 14"
            stroke="white"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    );
  }
);
