import React, { useContext, useMemo } from "react";
import { createContext, useEffect, useState } from "react";
import { IQuizListItem, IQuizTheme } from "../types";
import {
  getRecommendedQuizzesForUser,
  getThemesAndQuizzes,
} from "../firebaseClient";
import { UserContext } from "./UserProvider";
import { useTranslation } from "react-i18next";

export const QuizzesProvider: React.FC<{ children: React.ReactNode }> =
  React.memo(({ children }) => {
    const { fbUser, ownUser: user } = useContext(UserContext);
    const { i18n } = useTranslation();

    const lang = useMemo(
      () => user.data?.lang || i18n.resolvedLanguage,
      [user.data?.lang, i18n.resolvedLanguage]
    );

    const [recommendedQuiz, setRecommendedQuizzes] = useState<{
      list: IQuizListItem[];
      isFetching: boolean;
    }>({ list: [], isFetching: true });
    const [quizThemes, setQuizThemes] = useState<{
      list: IQuizTheme[];
      isFetching: boolean;
    }>({ isFetching: true, list: [] });

    useEffect(() => {
      // in case auth state changed and user is not anonymous
      // to wait for fetching user data (lang and difficulty)
      if (fbUser?.isAnonymous === false && user.data === null) {
        return;
      }

      let difficulty;

      if (user.data) {
        difficulty = user.data.difficulty;
      }

      setRecommendedQuizzes({ isFetching: true, list: [] });
      getRecommendedQuizzesForUser(user.data?.userId, lang, difficulty)
        .then((response) =>
          setRecommendedQuizzes({ list: response, isFetching: false })
        )
        .catch((error) => {
          console.log(error);
          setRecommendedQuizzes({ isFetching: true, list: [] });
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, user.data?.userId, user.data?.difficulty]);

    useEffect(() => {
      // in case auth state changed and user is not anonymous
      // to wait for fetching user data (lang and difficulty)
      if (fbUser?.isAnonymous === false && user.data === null) {
        return;
      }

      setQuizThemes((current) => ({ ...current, isFetching: true }));
      getThemesAndQuizzes(lang, user.data?.difficulty)
        .then((response) => {
          setQuizThemes({ list: response, isFetching: false });
        })
        .catch((error) => {
          console.log(error);
          setQuizThemes((current) => ({ ...current, isFetching: false }));
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lang, user.data?.difficulty]);

    return (
      <RecommendedQuizzesContext.Provider value={recommendedQuiz}>
        <QuizThemesContext.Provider value={quizThemes}>
          {children}
        </QuizThemesContext.Provider>
      </RecommendedQuizzesContext.Provider>
    );
  });

QuizzesProvider.displayName = "QuizzesProvider";

export const RecommendedQuizzesContext = createContext<{
  list: IQuizListItem[];
  isFetching: boolean;
}>({ list: [], isFetching: false });
export const QuizThemesContext = createContext<{
  list: IQuizTheme[];
  isFetching: boolean;
}>({ list: [], isFetching: false });
