import React from "react";
import styles from "./Loader.module.scss";

interface IPropTypes {
  color?: string;
  borderSize?: number;
  size?: number;
}

export const Loader: React.FC<IPropTypes> = React.memo(
  ({ color = "rgba(255, 247, 48, 1)", borderSize = 10, size = 50 }) => (
    <div
      className={styles.Loader}
      style={{
        borderLeftColor: color,
        borderWidth: `${borderSize}rem`,
        width: `${size}rem`,
        height: `${size}rem`,
      }}
      data-role="loader"
    />
  )
);

export const WrappedLoader: React.FC<IPropTypes & { bgColor?: string }> =
  React.memo(({ bgColor, ...rest }) => {
    return (
      <div
        className={styles.LoaderWrapper}
        style={bgColor ? { backgroundColor: bgColor } : undefined}
      >
        <Loader {...rest} />
      </div>
    );
  });
