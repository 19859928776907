import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { UserContext } from "../../providers/UserProvider";
import Select from "../../components/_base/Select/Select";
import { Button } from "../../components/_base/Button/Button";
import { Text } from "../../components/_base/Text/Text";
import { IOwnUser } from "../../types";
import { PATH } from "../../routes";
import { setUserInfo } from "../../firebaseClient";
import GameLevelSelect from "./parts/DifficultyLevelSelect";
import { getLangsOptions, setLanguage as updateI18nLang } from "../../i18n";
import styles from "./ProfileSettingsScreen.module.scss";

interface IPropTypes {}

const ProfileSettingsScreen: React.FC<IPropTypes> = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const {
    ownUser: { data: user, update: updateUserData },
  } = useContext(UserContext);

  const location = useLocation();

  const [lang, setLang] = useState<string | null>(user?.lang ?? null);
  const [difficulty, setDifficulty] = useState<IOwnUser["difficulty"]>(
    user?.difficulty ?? null
  );

  const langOptions = useMemo(
    () => getLangsOptions(t, i18n.services.resourceStore.data),
    [t, i18n.services.resourceStore.data]
  );

  const selectedLang = useMemo(
    () => langOptions.find((l) => l.value === lang),
    [langOptions, lang]
  );

  const isSettingsScreen = useMemo(
    () => location.pathname === PATH.profileSettings,
    [location]
  );

  const onUpdateUserInfo = useCallback(
    (data: { lang?: string; difficulty?: IOwnUser["difficulty"] }) => {
      if (!user) {
        return;
      }

      const { userId, userName, photoURL = null, lang, difficulty } = user;
      setUserInfo({
        userId,
        userName,
        photoURL,
        lang,
        difficulty,
        ...data,
      }).then(() => updateUserData(data));
    },
    [user, updateUserData]
  );

  const debouncedOnUpdateUserInfo = useMemo(
    () => debounce(onUpdateUserInfo, 700),
    [onUpdateUserInfo]
  );

  const onSelectDifficulty = useCallback(
    (value: IOwnUser["difficulty"]) => {
      setDifficulty(value);
      debouncedOnUpdateUserInfo({ difficulty: value });
    },
    [debouncedOnUpdateUserInfo]
  );

  const onSelectLang = useCallback(
    (selected: { value: string; label: string } | null) => {
      const newLang = selected?.value ?? "en";
      setLang(newLang);
      updateI18nLang(newLang);
      onUpdateUserInfo({ lang: newLang });
    },
    [onUpdateUserInfo]
  );

  useEffect(() => {
    // in case user data fetched after first rendering
    if (user) {
      setLang(user.lang);
      setDifficulty(user.difficulty);
    }
  }, [user]);

  if (!user) {
    return null;
  }

  return (
    <div className={cn({ [styles.ProfileSettingsScreen]: isSettingsScreen })}>
      <div className={styles.ProfileSettingsScreen__Title}>
        {`${t("hello")}, ${user.userName}!`}
      </div>
      <GameLevelSelect
        difficulty={difficulty}
        onSelectDifficulty={onSelectDifficulty}
      />
      {!isSettingsScreen && (
        <Text
          fontSize="16rem"
          color="#ccc"
          fontFamily="Roboto"
          fontWeight={400}
          margin="0 0 20rem"
        >
          {t("appLanguage")}
        </Text>
      )}
      <Select
        placeholder={t("chooseLanguage")}
        options={langOptions}
        value={selectedLang}
        onSelect={onSelectLang}
      />
      {isSettingsScreen && (
        <>
          <Button
            bgColor="#fff"
            extraClass={styles.ProfileSettingsScreen__ButtonGo}
            onClick={() => navigate(PATH.root)}
          >
            {t("letsGo")}
          </Button>
          <Text color="rgba(255, 255, 255, 0.50)" fontSize="12rem">
            {t("profileSettingsInfo")}
          </Text>
        </>
      )}
    </div>
  );
};

ProfileSettingsScreen.displayName = "ProfileSettingsScreen";

export default ProfileSettingsScreen;
