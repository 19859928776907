import { User } from "firebase/auth";
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import defaultBg from "../../assets/images/quiz_bg.png";
import AuthScreen from "../../screens/AuthScreen/AuthScreen";
import { WrappedLoader } from "../../components/_base/Loader/Loader";
import { Quiz } from "../../components/Quiz/Quiz";
import { UserContext } from "../../providers/UserProvider";
import { QuizCompleted } from "../../components/QuizCompleted/QuizCompleted";
import { Text } from "../../components/_base/Text/Text";
import {
  firebaseAuth,
  getQuizById,
  getQuizResultByUserId,
  getQuizResults,
  saveQuizResult,
} from "../../firebaseClient";
import {
  IQuizResponse,
  IQuizUserResult,
  QuizMode,
  QuizModeColors,
} from "../../types";
import { QuizFriendShare } from "./QuizFriendShare";
import { DefaultQuizLanding } from "./DefaultQuizLanding";
import styles from "./QuizScreen.module.scss";

export interface IOnCompleteData {
  correctCount: number; // for QuizCompleted
  answers: Array<number | null>; // for backend
  answerTimes: number[]; // for QuizCompleted, backend
  answerPoints: number[]; // for QuizCompleted, backend
  scores: number; // for QuizCompleted, backend
}

const DEFAULT_COMPLETE_DATA: IOnCompleteData = {
  correctCount: 0,
  answers: [],
  answerPoints: [],
  answerTimes: [],
  scores: 1,
};

export const QuizScreen: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const { quizId } = useParams();
  const [searchParams] = useSearchParams();

  const { ownUser, fbUser } = useContext(UserContext);

  const [isShowCompleted, setIsShowCompleted] = useState(false);
  const [isShowAuthScreen, setIsShowAuthScreen] = useState(false);
  const [isShowFriendShare, setIsFriendShare] = useState(false);
  const [isShowDefaultLanding, setIsShowDefaultLanding] = useState(false);

  const [quizResp, setQuizResp] = useState<IQuizResponse | null>(null);
  const [quizResults, setQuizResults] = useState<IQuizUserResult[]>([]);
  const [friend, setFriend] = useState<IQuizUserResult | undefined | null>( // 'undefined' is like a isFetching state
    null
  );
  const [completeData, setCompleteData] = useState<IOnCompleteData>(
    DEFAULT_COMPLETE_DATA
  );

  const [mode, setMode] = useState(() => {
    let modeResult: QuizMode;
    const m = searchParams.get("m");
    if (m && Object.values(QuizMode).includes(m as QuizMode)) {
      modeResult = m as QuizMode;
    } else {
      modeResult = QuizMode.competition;
    }
    return modeResult;
  });

  const [timerDuration, friendId] = useMemo<
    [timerDuration: number | undefined, friendId: string | null]
  >(() => {
    let timerResult: number | undefined;
    const friendIdResult = searchParams.get("u");
    const timer = searchParams.get("timer");
    if (timer) {
      const duration = parseInt(timer);
      if (!Number.isNaN(duration)) {
        timerResult = duration;
      }
    }

    return [timerResult, friendIdResult];
  }, [searchParams]);

  const onStartQuiz = useCallback((m: QuizMode) => {
    setMode(m);
    setIsFriendShare(false);
    setIsShowDefaultLanding(false);
  }, []);

  const onComplete = useCallback(
    (data: IOnCompleteData) => {
      function complete(fbUser: User, isNeedUpdateUser: boolean) {
        const { answers, answerTimes, answerPoints, scores } = data;
        const quizMode = mode === QuizMode.competition ? "speed" : undefined;
        setCompleteData(data);
        setIsShowCompleted(true);

        if (quizId) {
          saveQuizResult({
            quizId,
            userId: fbUser.uid,
            userName: fbUser.displayName,
            photoURL: fbUser.photoURL,
            score: scores,
            answers,
            answerPoints,
            answerTimes,
            referrerUserId: friendId,
            type: quizMode,
          }).then(() => {
            if (isNeedUpdateUser) {
              ownUser.silentFetchUser();
            }
          });
        }

        const myIndex = quizResults.findIndex(
          ({ userId }) => userId === fbUser.uid
        );
        const myQuizResult: IQuizUserResult = {
          userId: fbUser.uid,
          userName: fbUser.displayName || "",
          photoURL: fbUser.photoURL || "",
          answerPoints,
          answers,
          answerTimes,
          score: scores,
        };

        if (myIndex !== -1) {
          quizResults[myIndex] = myQuizResult;
        } else {
          quizResults.push(myQuizResult);
        }

        setQuizResults([...quizResults].sort((a, b) => b.score - a.score));
      }

      if (firebaseAuth.currentUser) {
        complete(firebaseAuth.currentUser, true);
      } else {
        setIsShowAuthScreen(true);
        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
          if (user) {
            unsubscribe();
            setIsShowAuthScreen(false);
            // no need to update user here. Because login just happend and user will be fetched in UserProvider
            complete(user, false);
          }
        });
      }
    },
    [quizId, ownUser, friendId, quizResults, mode]
  );

  useLayoutEffect(() => {
    if (quizId) {
      if (friendId) {
        setIsFriendShare(true);
        setFriend(undefined);
        getQuizResultByUserId(quizId, friendId)
          .then((fr) => {
            setFriend(fr ? fr : null);
          })
          .catch((error) => {
            console.error(error);
            setFriend(null);
          });
      } else {
        if (!locationState?.isSkipDefaultLanding) {
          setIsShowDefaultLanding(true);
        }
      }

      getQuizById(quizId)
        .then(setQuizResp)
        .catch((error) => {
          console.error(error);
          navigate("/");
        });

      getQuizResults(quizId)
        .then(setQuizResults)
        .catch((error) => {
          console.error(error);
        });
    }

    return () => {
      setIsShowCompleted(false);
      setIsFriendShare(false);
      setIsShowAuthScreen(false);
      setIsShowDefaultLanding(false);
      setQuizResp(null);
      setQuizResults([]);
      setFriend(null);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId]);

  return (
    <div
      className={styles.QuizScreen}
      style={{
        backgroundImage: `url(${quizResp?.backgroundUrl || defaultBg})`,
        "--mode-color": QuizModeColors[mode],
      }}
    >
      {/* <div className={styles.QuizScreen__TopGradient} /> */}
      <div className={styles.QuizScreen__Header}>
        <Text color="#ccc" fontWeight={400} fontSize={14}>
          {quizResp?.topic || ""}
        </Text>
      </div>
      {(() => {
        if (isShowCompleted && quizResp) {
          return (
            <QuizCompleted
              completeData={completeData}
              quizResp={quizResp}
              myPhotoUrl={fbUser?.photoURL || ""}
              friend={friend}
              leaderboardInfo={quizResults}
              mode={mode}
            />
          );
        } else if (quizResp && friend !== undefined) {
          // friend === undefined means that 'friend' is fetching now, so procced with the <WrappedLoader />
          if (isShowFriendShare && friend) {
            return (
              <QuizFriendShare
                quiz={quizResp}
                friend={friend}
                mode={mode}
                onStartQuiz={onStartQuiz}
              />
            );
          }
          if (isShowDefaultLanding) {
            return (
              <DefaultQuizLanding quiz={quizResp} onStartQuiz={onStartQuiz} />
            );
          }
          return (
            <Quiz
              key={quizId}
              quiz={quizResp.quiz}
              mode={mode}
              friend={friend}
              myPhotoURL={fbUser?.photoURL || ""}
              timerDuration={timerDuration}
              onComplete={onComplete}
            />
          );
        }
        return <WrappedLoader />;
      })()}
      {isShowAuthScreen && (
        <AuthScreen
          title={t("passedQuiz")}
          imgSrc="//s2.vcdn.biz/static/203724541/hulk.png"
          onSuccess={() => setIsShowAuthScreen(false)}
        />
      )}
    </div>
  );
});

QuizScreen.displayName = "QuizScreen";
