import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { BeforeQuizLanding } from '../../components/BeforeQuizLanding/BeforeQuizLanding';
import { IQuizResponse, QuizMode } from '../../types';

interface IPropTypes {
  quiz: IQuizResponse;
  onStartQuiz: (mode: QuizMode) => void;
}

export const DefaultQuizLanding: React.FC<IPropTypes> = React.memo(
  ({ quiz, onStartQuiz }) => {
    const navigate = useNavigate();

    const onBack = useCallback(() => {
      navigate(-1);
    }, [navigate]);

    return (
      <BeforeQuizLanding
        bgUrl={quiz.backgroundUrl}
        title={quiz.topic}
        description={quiz.quiz_introduction}
        type='default'
        onStartQuiz={onStartQuiz}
        onBack={onBack}
      />
    );
  }
);
DefaultQuizLanding.displayName = 'DefaultQuizLanding';
