import React from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { FlexRow } from "../../../components/_base/Flex/Flex";
import Image from "../../../components/_base/Image/Image";
import { Text } from "../../../components/_base/Text/Text";
import { IOwnUser } from "../../../types";
import styles from "./index.module.scss";

interface IPropTypes {
  difficulty: IOwnUser["difficulty"];
  onSelectDifficulty: (level: IOwnUser["difficulty"]) => void;
}

const DifficultyLevelSelect: React.FC<IPropTypes> = React.memo(
  ({ difficulty, onSelectDifficulty }) => {
    const { t } = useTranslation();

    return (
      <div className={styles.DifficultyLevelSelect}>
        <Text
          fontSize="16rem"
          color="#ccc"
          fontFamily="Roboto"
          fontWeight={400}
        >
          {t("chooseGameMode")}
        </Text>
        <FlexRow gap="20rem">
          <div
            className={cn({
              [styles.DifficultyLevelSelect__Type]: true,
              [styles.DifficultyLevelSelect__Type_State_Selected]:
                difficulty === 0,
            })}
            onClick={() => onSelectDifficulty(0)}
          >
            <Image
              src="//s4.vcdn.biz/static/203668581/low.png"
              alt="Childish"
            />
            <Text
              fontSize="14rem"
              fontWeight={500}
              fontFamily="Roboto"
              color="#fff"
            >
              {t("childish")}
            </Text>
          </div>
          <div
            className={cn({
              [styles.DifficultyLevelSelect__Type]: true,
              [styles.DifficultyLevelSelect__Type_State_Selected]:
                difficulty === 0.5,
            })}
            onClick={() => onSelectDifficulty(0.5)}
          >
            <Image src="//s7.vcdn.biz/static/203724231/adult.png" alt="adult" />
            <Text
              fontSize="14rem"
              fontWeight={500}
              fontFamily="Roboto"
              color="#fff"
            >
              {t("adult")}
            </Text>
          </div>
          <div
            className={cn({
              [styles.DifficultyLevelSelect__Type]: true,
              [styles.DifficultyLevelSelect__Type_State_Selected]:
                difficulty === 1,
            })}
            onClick={() => onSelectDifficulty(1)}
          >
            <Image src="//s9.vcdn.biz/static/203668631/hard.png" alt="hard" />
            <Text
              fontSize="14rem"
              fontWeight={500}
              fontFamily="Roboto"
              color="#fff"
            >
              {t("hard")}
            </Text>
          </div>
        </FlexRow>
      </div>
    );
  }
);

export default DifficultyLevelSelect;
