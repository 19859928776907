import React, { useEffect, useMemo } from "react";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { IOnCompleteData } from "../../screens/QuizScreen/QuizScreen";
import { Text } from "../_base/Text/Text";
import { Avatar } from "../_base/Avatar/Avatar";
import { CoinIcon, PieIcon, StopwatchIcon } from "../_base/Icons/Icons";
import { getPlural } from "../../utils/getPlural";
import { getSum } from "../../utils/getSum";
import { startConfettiAnimation } from "../../utils/confetti";
import { IQuiz, IQuizUserResult } from "../../types";
import crownSvg from "../../assets/images/crown.svg";
import styles from "./QuizCompleted.module.scss";

export const SingleInfo: React.FC<{ quizScores: number }> = React.memo(
  ({ quizScores }) => {
    const { t } = useTranslation();

    useEffect(() => {
      startConfettiAnimation();
    }, []);

    return (
      <div className={styles.SingleInfo}>
        <Text fontSize={20} color="white">
          {t("congratulationsQuizComplete")}
        </Text>
        <Text
          classNames={styles.SingleInfo__Scores}
          fontSize={28}
          fontFamily="Yanone Kaffeesatz"
          color="white"
        >
          <span>+{quizScores + " "}</span>
          {getPlural(quizScores, "coin")}
        </Text>
      </div>
    );
  }
);

export const DuelInfo: React.FC<{
  quiz: IQuiz;
  completeData: IOnCompleteData;
  friend: IQuizUserResult;
  myPhotoUrl: string;
}> = React.memo(({ quiz, completeData, friend, myPhotoUrl }) => {
  const { t } = useTranslation();

  const dGameResult = useMemo<number>(() => {
    return completeData.scores - friend.score;
  }, [completeData.scores, friend.score]);

  const [myTime, friendTime] = useMemo<[number, number]>(() => {
    return [
      Math.round(getSum(completeData.answerTimes)),
      Math.round(getSum(friend.answerTimes)),
    ];
  }, [completeData.answerTimes, friend.answerTimes]);

  const friendCorrectCount = useMemo<number>(() => {
    let result = 0;
    quiz.forEach((question, i) => {
      const answer = friend.answers[i];
      if (answer !== null && question.answers[answer].is_correct) {
        ++result;
      }
    });
    return result;
  }, [quiz, friend.answers]);

  const headerText = useMemo<string>(() => {
    let result: string;
    if (dGameResult > 0) {
      result = t("youWin");
    } else if (dGameResult < 0) {
      result = t("youLost");
    } else {
      // === 0
      result = t("draw");
    }
    return result;
  }, [dGameResult, t]);

  return (
    <div className={styles.DuelInfo}>
      <Text
        fontSize={28}
        fontWeight={500}
        fontFamily="Yanone Kaffeesatz"
        color="white"
      >
        {headerText}
      </Text>
      <Players
        dGameResult={dGameResult}
        myPhotoUrl={myPhotoUrl}
        friendPhotoUrl={friend.photoURL}
        myScores={completeData.scores}
      />
      <div className={styles.DuelInfo__Stats}>
        <div className={styles.DuelInfo__Stats__Row}>
          <span>
            {myTime} {t("sec")}
          </span>
          <StopwatchIcon />
          <span>
            {friendTime} {t("sec")}
          </span>
        </div>
        <div className={styles.DuelInfo__Stats__Row}>
          <span>{`${completeData.correctCount}/${quiz.length}`}</span>
          <PieIcon />
          <span>{`${friendCorrectCount}/${quiz.length}`}</span>
        </div>
        <div className={styles.DuelInfo__Stats__Row}>
          <span>{`${completeData.scores} ${getPlural(
            completeData.scores,
            "coin"
          )}`}</span>
          <CoinIcon />
          <span>{`${friend.score} ${getPlural(friend.score, "coin")}`}</span>
        </div>
      </div>
    </div>
  );
});

const Players: React.FC<{
  dGameResult: number;
  myPhotoUrl: string;
  friendPhotoUrl: string;
  myScores: number;
}> = React.memo(({ dGameResult, myPhotoUrl, friendPhotoUrl, myScores }) => {
  const isDraw = useMemo(() => dGameResult === 0, [dGameResult]);

  useEffect(() => {
    if (dGameResult > 0) {
      startConfettiAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.Players}>
      <div className={styles.Players__Wrapper}>
        <PlayerAvatar
          photoUrl={myPhotoUrl}
          isWinner={dGameResult > 0}
          isDraw={isDraw}
          borderColor="yellow"
        />
        <PlayerAvatar
          photoUrl={friendPhotoUrl}
          isWinner={dGameResult < 0}
          isDraw={isDraw}
          borderColor="white"
        />
      </div>
      <div
        className={styles.Players__Coins}
        style={{ top: isDraw ? "75%" : "50%" }}
      >
        <Text
          fontSize={32}
          lineHeight="32rem"
          fontFamily="Yanone Kaffeesatz"
          fontWeight={700}
          color="#FEDE67"
          textAlign="center"
        >{`+ ${myScores}`}</Text>
        <Text fontSize={20} fontWeight={500} lineHeight="22rem" color="#fff">
          {getPlural(myScores, "coin")}
        </Text>
      </div>
    </div>
  );
});

Players.displayName = "Players";

const PlayerAvatar: React.FC<{
  photoUrl: string;
  isWinner: boolean;
  isDraw: boolean;
  borderColor: "yellow" | "white";
}> = ({ photoUrl, isWinner, isDraw, borderColor }) => {
  const size = useMemo(() => {
    if (isDraw) {
      return 50;
    } else if (isWinner) {
      return 60;
    } else {
      return 40;
    }
  }, [isWinner, isDraw]);

  return (
    <div
      className={cn(styles.Players__Item, {
        [styles.Players__Item_State_Winner]: isWinner,
        [styles.Players__Item_Color_Yellow]: borderColor === "yellow",
        [styles.Players__Item_Color_White]: borderColor === "white",
      })}
    >
      <div
        className={styles.Players__CrownIcon}
        style={{ backgroundImage: `url(${crownSvg})` }}
      />
      <Avatar photoURL={photoUrl} size={size} />
    </div>
  );
};
