import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BeforeQuizLanding } from "../../components/BeforeQuizLanding/BeforeQuizLanding";
import { getImageByPrompt, getLandingDataByPrompt } from "../../firebaseClient";
import { QuizMode } from "../../types";
import {
  CustomNavBarNavigationCheck,
  CustomNavButtonIsSelectedFunc,
  INavBarCustomFuncsContextValue,
  NavBarCustomFuncsContext,
} from "../../components/NavBar/NavBar";
import { PATH } from "../../routes";

interface IPropTypes {
  prompt: string;
  isQuizCreated: boolean;
  onGotoQuiz: (mode: QuizMode) => void;
  onClose: () => void;
}

export const QuizGenerationScreen: React.FC<IPropTypes> = React.memo(
  ({ prompt, isQuizCreated, onGotoQuiz, onClose }) => {
    const [quizData, setQuizData] = useState<{
      title: string;
      description: string;
    } | null>(null);
    const [quizBase64Bg, setQuizBase64Bg] = useState<string | null>(null);

    const customNavIsSelected = useCallback<CustomNavButtonIsSelectedFunc>(
      (buttonPath, currentPath) => {
        if (buttonPath === PATH.root && currentPath === PATH.root) {
          return false;
        }
      },
      []
    );

    const customNavNavigationCheck = useCallback<CustomNavBarNavigationCheck>(
      (targetPath) => {
        if (targetPath === PATH.root) {
          onClose();
          return false;
        }
      },
      [onClose]
    );

    const navContextValue = useMemo<INavBarCustomFuncsContextValue>(() => {
      return {
        customIsSelected: customNavIsSelected,
        customNavigationCheck: customNavNavigationCheck,
      };
    }, [customNavIsSelected, customNavNavigationCheck]);

    useEffect(() => {
      getLandingDataByPrompt(prompt)
        .then((data) => {
          setQuizData(data);

          getImageByPrompt(data.imagePrompt)
            .then((image) => {
              setQuizBase64Bg(image);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <NavBarCustomFuncsContext.Provider value={navContextValue}>
        <BeforeQuizLanding
          bgUrl={
            quizBase64Bg ? `data:image/jpeg;base64,${quizBase64Bg}` : undefined
          }
          type="generation"
          isButtonLoading={!isQuizCreated}
          title={quizData?.title || ""}
          description={quizData?.description || ""}
          onStartQuiz={onGotoQuiz}
          onBack={onClose}
        />
      </NavBarCustomFuncsContext.Provider>
    );
  }
);
QuizGenerationScreen.displayName = "QuizGenerationScreen";
