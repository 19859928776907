import { useEffect, useRef } from "react";
import { useFirstMountState } from "./useFirstMountState";

const useUpdateEffect: typeof useEffect = (
  effect: (deps: unknown[]) => void,
  deps: React.DependencyList = []
) => {
  const isFirstMount = useFirstMountState();
  const prevDeps = useRef<unknown[]>([]);

  useEffect(() => {
    if (!isFirstMount) {
      effect([...prevDeps.current]);
      prevDeps.current = [...deps];
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useUpdateEffect;
