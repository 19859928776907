import React, { useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import qrImg from "../../assets/images/qr.svg";
import qudroImg from "../../assets/images/quadro_glasses.png";
import { FlexSpacer } from "../_base/Flex/Flex";
import { Text } from "../_base/Text/Text";
import styles from "./DesktopStub.module.scss";

export const DesktopStub: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const [isHidden, setIsHidden] = useState(true);
  const [fontSize, setFontSize] = useState(1);
  const prevWidthRef = useRef<number | undefined>();

  useLayoutEffect(() => {
    function onResize(event?: Event) {
      if (prevWidthRef.current !== window.innerWidth || event) {
        processFontSize();
        processStub();
      } else {
        setTimeout(onResize, 50);
      }

      prevWidthRef.current = window.innerWidth;

      function processFontSize() {
        const targetHeight = 600;
        const coef = window.innerHeight / targetHeight;
        setFontSize(coef);
      }

      function processStub() {
        const coef = window.innerHeight / window.innerWidth;
        setIsHidden(coef > 1.3);
      }
    }
    window.addEventListener("resize", onResize);
    document.addEventListener("DOMContentLoaded", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
      document.removeEventListener("DOMContentLoaded", onResize);
    };
  }, []);

  return (
    <div
      className={styles.DesktopStub}
      style={{ display: isHidden ? "none" : "block", fontSize }}
    >
      <div className={styles.DesktopStub__BgImg} />
      <div className={styles.DesktopStub__Content}>
        <FlexSpacer />
        <img className={styles.DesktopStub__AvatarImg} src={qudroImg} alt="" />
        <div>
          <Text fontSize="24em" lineHeight="1.2" fontWeight={450} color="white">
            {t("hello")} 👋
          </Text>
        </div>
        <Text fontSize="24em" lineHeight="1.2" fontWeight={450} color="#ccc">
          {t("desktopStubTitle")}
        </Text>
        <FlexSpacer />
        <img
          className={styles.DesktopStub__QRImg}
          src={qrImg}
          alt="mobile qr code"
        />
        <Text fontSize="24em" lineHeight="1.2" color="#fff730">
          {t("scanQrCode")}
        </Text>
        <FlexSpacer />
      </div>
    </div>
  );
});

DesktopStub.displayName = "DesktopStub";
