export function isElementInViewport(element: Element, part?: number): boolean {
  const rect = element.getBoundingClientRect();
  const top = part ? rect.height * part >= Math.abs(rect.top) : rect.top >= 0;

  return (
    top &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
