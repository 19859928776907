import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { throttle } from "lodash";
import { useTranslation } from "react-i18next";
import { AllQuizzesList } from "../../components/AllQuizzesList/AllQuizzesList";
import AuthScreen from "../AuthScreen/AuthScreen";
import { Button } from "../../components/_base/Button/Button";
import { CategoriesList } from "../../components/CategoriesList/CategoriesList";
import { Header } from "../../components/Header/Header";
import { NavBar } from "../../components/NavBar/NavBar";
import { QuizPrompt } from "../../components/QuizPrompt/QuizPrompt";
import { QuizzesList } from "../../components/QuizzesList/QuizzesList";
import { RecommendedQuizzesContext } from "../../providers/QuizzesProvider";
import { UserContext } from "../../providers/UserProvider";
import Transition from "../../components/_base/Transition/Transition";
import Image from "../../components/_base/Image/Image";
import AppModeSelect from "../../components/AppModeSelect/AppModeSelect";
import { useScrollOpacityControl } from "../../components/Header/useScrollOpacityController";
import { createQuiz, firebaseAuth } from "../../firebaseClient";
import { QuizMode } from "../../types";
import { SessionStorageKeys, Storage } from "../../utils/storage";
import { isElementInViewport } from "../../utils";
import { QuizGenerationScreen } from "./QuizGenerationScreen";
import styles from "./MainScreen.module.scss";

export const MainScreen: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { list: recommendedQuizzes, isFetching: isFetchingRecommendedQuizzes } =
    useContext(RecommendedQuizzesContext);
  const { ownUser: user } = useContext(UserContext);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const createQuizAbortRef = useRef<AbortController | null>(null);
  const appModeSelectRef = useRef<HTMLDivElement | null>(null);

  const [isShowAuthScreen, setIsShowAuthScreen] = useState(false);
  const [isShowGenerationScreen, setIsShowGenerationScreen] = useState(false);
  const [isShowAllQuizzesScreen, setIsShowAllQuizzesScreen] = useState(false);
  const [isShowHeaderAppModeSelect, setIsShowHeaderAppModeSelect] =
    useState(false);
  const [prompt, setPrompt] = useState("");
  const [createdQuizId, setCreatedQuizId] = useState<string | null>(null);

  const { setHeader, setScroller } = useScrollOpacityControl(10);

  const onCreateQuiz = useCallback(
    (prompt: string) => {
      async function sendQuizRequest(userId: string) {
        setIsShowGenerationScreen(true);
        const abort = new AbortController();
        createQuizAbortRef.current = abort;
        const { quizId } = await createQuiz({
          userId,
          topic: prompt,
          language: user.data?.lang,
          difficulty: user.data?.difficulty,
        });
        if (!abort.signal.aborted) {
          setCreatedQuizId(quizId);
          void user.silentFetchUser();
          createQuizAbortRef.current = null;
        }

        // setTimeout(() => {
        //   if (!abort.signal.aborted) {
        //     setCreatedQuizId('06a57be343d9642462f85cf8a8e7cb14');
        //     createQuizAbortRef.current = null;
        //   } else {
        //     console.log('!!!!aborted');
        //   }
        // }, 5000);
      }

      if (firebaseAuth.currentUser) {
        sendQuizRequest(firebaseAuth.currentUser.uid);
      } else {
        setIsShowAuthScreen(true);
        const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
          if (user) {
            unsubscribe();
            setIsShowAuthScreen(false);
            sendQuizRequest(user.uid);
          }
        });
      }
    },
    [user]
  );

  const onCreateQuizClick = useCallback(() => {
    if (prompt) {
      onCreateQuiz(prompt);
    }
  }, [prompt, onCreateQuiz]);

  const onGotoQuiz = useCallback(
    (mode: QuizMode) => {
      if (createdQuizId) {
        navigate(`/quiz/${createdQuizId}?m=${mode}`, {
          state: { isSkipDefaultLanding: true },
        });
      }
    },
    [createdQuizId, navigate]
  );

  const onScrollerRef = useCallback(
    (ref: HTMLDivElement | null) => {
      scrollRef.current = ref;
      setScroller(ref);
    },
    [setScroller]
  );

  const onScrollContent = useCallback(() => {
    setIsShowHeaderAppModeSelect((current) => {
      if (!appModeSelectRef.current) {
        return current;
      }

      const newValue = !isElementInViewport(appModeSelectRef.current);
      return current !== newValue ? newValue : current;
    });
  }, []);

  const throtthledOnScrollContent = useMemo(
    () => throttle(onScrollContent, 150, { leading: false, trailing: true }),
    [onScrollContent]
  );

  useLayoutEffect(() => {
    Storage.removeItem(
      window.sessionStorage,
      SessionStorageKeys.MY_LIST_SCROLL
    );

    if (scrollRef.current) {
      const scrollTop = parseInt(
        Storage.getItem(
          window.sessionStorage,
          SessionStorageKeys.MAIN_SCROLL
        ) || ""
      );
      if (!Number.isNaN(scrollTop)) {
        scrollRef.current.scrollTop = scrollTop;
      }
    }

    return () => {
      if (scrollRef.current) {
        Storage.setItem(
          window.sessionStorage,
          SessionStorageKeys.MAIN_SCROLL,
          // eslint-disable-next-line react-hooks/exhaustive-deps
          scrollRef.current.scrollTop.toString()
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isShowGenerationScreen) {
      setCreatedQuizId(null);
      createQuizAbortRef.current?.abort();
    }
  }, [isShowGenerationScreen]);

  return (
    <div
      className={styles.MainScreen}
      ref={onScrollerRef}
      onScroll={throtthledOnScrollContent}
    >
      <Header
        ref={setHeader}
        isShowAppModeSelect={isShowHeaderAppModeSelect}
        onClickSignIn={() => setIsShowAuthScreen(true)}
      />
      <NavBar />
      <div className={styles.MainScreen__Content}>
        <div className={styles.MainScreen__Banner}>
          <Image src="//s8.vcdn.biz/static/203724241/banner.png" alt="banner" />
        </div>
        <AppModeSelect ref={appModeSelectRef} />
        <QuizPrompt
          prompt={prompt}
          onClick={onCreateQuizClick}
          onPromptChange={setPrompt}
        />
        <CategoriesList />
        <div className={styles.MainScreen__RecommendedWrapper}>
          <QuizzesList
            title={t("youMayLike")}
            items={recommendedQuizzes}
            isFetching={isFetchingRecommendedQuizzes}
            isShowSharingButton={false}
          />
          <Button
            type="outlined"
            label={t("allQuizzes")}
            onClick={() => {
              setIsShowAllQuizzesScreen(true);
            }}
          />
        </div>
      </div>
      <Transition isShow={isShowAuthScreen}>
        <AuthScreen
          title={t("enter")}
          imgSrc="//s6.vcdn.biz/static/203724221/1.png"
          onSuccess={() => setIsShowAuthScreen(false)}
        />
      </Transition>
      <Transition isShow={isShowAllQuizzesScreen}>
        <AllQuizzesList
          onCloseList={() => {
            setIsShowAllQuizzesScreen(false);
          }}
        />
      </Transition>
      {isShowGenerationScreen && (
        <QuizGenerationScreen
          prompt={prompt}
          isQuizCreated={Boolean(createdQuizId)}
          onGotoQuiz={onGotoQuiz}
          onClose={() => {
            setIsShowGenerationScreen(false);
          }}
        />
      )}
    </div>
  );
});

MainScreen.displayName = "MainScreen";
