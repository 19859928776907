import React from "react";
import styles from "./Flex.module.scss";

export const FlexSpacer: React.FC<{ grow?: number }> = React.memo(
  ({ grow = 1 }) => {
    return <div style={{ flexGrow: grow }} />;
  }
);
FlexSpacer.displayName = "FlexSpacer";

export const FlexCenter: React.FC<{ children: React.ReactNode }> = React.memo(
  ({ children }) => {
    return <div className={styles.FlexCenter}>{children}</div>;
  }
);
FlexCenter.displayName = "FlexCenter";

export const FlexRow: React.FC<{ children: React.ReactNode; gap?: string }> =
  React.memo(({ children, gap }) => {
    return (
      <div className={styles.FlexRow} style={{ gap }}>
        {children}
      </div>
    );
  });
FlexCenter.displayName = "FlexCenter";
