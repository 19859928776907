import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../providers/UserProvider";
import { Avatar } from "../_base/Avatar/Avatar";
import { Text } from "../_base/Text/Text";
import { CoinsIcon, UserIcon } from "../_base/Icons/Icons";
import Transition from "../_base/Transition/Transition";
import Select from "../_base/Select/Select";
import AppModeSelect from "../AppModeSelect/AppModeSelect";
import { getLangsOptions, setLanguage as updateI18nLang } from "../../i18n";
import styles from "./Header.module.scss";

interface IPropTypes {
  onClickSignIn: () => void;
  isShowAppModeSelect?: boolean;
}

export const Header = React.memo(
  React.forwardRef<HTMLDivElement, IPropTypes>(
    ({ onClickSignIn, isShowAppModeSelect = false }, ref) => {
      const { fbUser: user, ownUser } = useContext(UserContext);

      const avatarUrl = useMemo(
        () => user?.providerData[0]?.photoURL,
        [user?.providerData]
      );

      return (
        <header className={styles.Header}>
          <div ref={ref} className={styles.Header__Gradient} />
          {Boolean(user) ? (
            <AuthUserHeader
              avatarUrl={avatarUrl}
              name={user!.displayName}
              scores={ownUser.data?.totalScore}
              isShowAppModeSelect={isShowAppModeSelect}
            />
          ) : (
            <AnonUserHeader
              isShowAppModeSelect={isShowAppModeSelect}
              onClickSignIn={onClickSignIn}
            />
          )}
        </header>
      );
    }
  )
);

Header.displayName = "Header";

const AuthUserHeader: React.FC<{
  name: string | null;
  avatarUrl?: string | null;
  scores?: number;
  isShowAppModeSelect?: boolean;
}> = ({ name, avatarUrl, scores, isShowAppModeSelect = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <Transition isShow={isShowAppModeSelect}>
        <AppModeSelect />
      </Transition>
      <Transition isShow={!isShowAppModeSelect}>
        <div className={styles.Header__User}>
          <Avatar
            extraClasses={styles.Header__Avatar}
            size={32}
            photoURL={avatarUrl}
          />
          <div className={styles.Header__UserName}>
            {`${t("hello")}, ${name}!`}
          </div>
        </div>
      </Transition>
      <ProfileScores scores={scores} />
    </>
  );
};

const AnonUserHeader: React.FC<{
  onClickSignIn: IPropTypes["onClickSignIn"];
  isShowAppModeSelect?: boolean;
}> = ({ onClickSignIn, isShowAppModeSelect = false }) => {
  const { t, i18n } = useTranslation();

  const rootRef = useRef<HTMLDivElement>(null);

  const [lang, setLang] = useState<string>(i18n.resolvedLanguage ?? "en");
  const [isLangSelectOpened, setIsLangSelectOpened] = useState(false);

  const langOptions = useMemo(
    () => getLangsOptions(t, i18n.services.resourceStore.data),
    [t, i18n.services.resourceStore.data]
  );

  const selectedLangOption = useMemo(
    () => langOptions.find((l) => l.value === lang),
    [langOptions, lang]
  );

  const onSelectLang = useCallback(
    (selected: { value: string; label: string } | null) => {
      const newLang = selected?.value ?? "en";

      setLang(newLang);
      updateI18nLang(newLang);
      setIsLangSelectOpened(false);
    },
    []
  );

  const onToggleOpenSelectLang = useCallback(() => {
    setIsLangSelectOpened((state) => !state);
  }, []);

  return (
    <div className={styles.Header__Anon} ref={rootRef}>
      <div className={styles.Header__AnonProfile} onClick={onClickSignIn}>
        <div className={styles.Header__ProfileIcon}>
          <UserIcon fill="none" stroke="#232531" />
        </div>
        <Transition isShow={!isShowAppModeSelect}>
          <span className={styles.Header__Label}>{t("enter")}</span>
        </Transition>
      </div>
      <Transition isShow={isShowAppModeSelect}>
        <AppModeSelect />
      </Transition>
      <div className={styles.Header__Lang}>
        <Select
          options={langOptions}
          value={selectedLangOption}
          control={
            <div
              className={styles.Header__SelectedLang}
              onClick={onToggleOpenSelectLang}
            >
              {lang}
            </div>
          }
          isOpened={isLangSelectOpened}
          onSelect={onSelectLang}
        />
      </div>
      <Transition isShow={isLangSelectOpened}>
        <div
          className={styles.Header__Overlay}
          onClick={() => setIsLangSelectOpened(false)}
        />
      </Transition>
    </div>
  );
};

export const ProfileScores: React.FC<{ scores?: number }> = React.memo(
  ({ scores = 0 }) => {
    return (
      <div className={styles.Header__Coins}>
        <Text
          color="#FEDE67"
          fontSize="20rem"
          fontWeight={700}
          fontFamily="Yanone Kaffeesatz"
          margin="0 5rem 0 0"
        >
          {scores}
        </Text>
        <CoinsIcon />
      </div>
    );
  }
);
