import cn from "classnames";
import React, { useCallback, useRef } from "react";
import { IAnswer } from "../../types";
import { Avatar } from "../_base/Avatar/Avatar";
import styles from "./Quiz.module.scss";

export type AnswerState =
  | "correct"
  | "incorrect"
  | "correctHighlighted"
  | undefined;

export const Answer: React.FC<{
  answer: IAnswer;
  index: number;
  answerState: AnswerState;
  friendPhotoURL: string | undefined; // highlighting answer with avatar
  myPhotoURL: string | undefined; // highlighting answer with avatar
  onAnswer: (aIndex: number) => void;
}> = React.memo(
  ({ answer, index, answerState, friendPhotoURL, myPhotoURL, onAnswer }) => {
    const spanRef = useRef<HTMLSpanElement | null>(null);

    const onClick = useCallback(() => {
      onAnswer(index);
    }, [index, onAnswer]);

    return (
      <div
        className={cn(styles.Answer, {
          [styles.Answer_State_Correct]:
            answerState === "correct" || answerState === "correctHighlighted",
          [styles.Answer_State_Incorrect]: answerState === "incorrect",
        })}
        onClick={onClick}
      >
        <span ref={spanRef}>{answer.text}</span>
        {answerState === "correct" || answerState === "correctHighlighted" ? (
          <CorrectIcon />
        ) : null}
        {answerState === "incorrect" && <IncorrectIcon />}
        {friendPhotoURL && (
          <Avatar
            size={24}
            photoURL={friendPhotoURL}
            extraClasses={cn(
              styles.Answer__Avatar,
              styles.Answer__Avatar_Friend,
              { [styles.Answer__Avatar_Friend_Combined]: Boolean(myPhotoURL) }
            )}
          />
        )}
        {myPhotoURL && (
          <Avatar
            size={24}
            photoURL={myPhotoURL}
            extraClasses={cn(styles.Answer__Avatar, styles.Answer__Avatar_My)}
          />
        )}
      </div>
    );
  }
);

Answer.displayName = "Answer";

const CorrectIcon: React.FC = React.memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.839 6.10749C13.2671 5.6437 13.2382 4.92066 12.7744 4.49254C12.3106 4.06442 11.5875 4.09334 11.1594 4.55714L6.63289 9.46088L5.25176 8.07975C4.80545 7.63344 4.08183 7.63344 3.63552 8.07975C3.1892 8.52606 3.1892 9.24968 3.63552 9.69599L5.85774 11.9182C6.07767 12.1381 6.37774 12.2589 6.6887 12.2527C6.99967 12.2465 7.29467 12.1138 7.50564 11.8853L12.839 6.10749Z"
        fill="#121212"
      />
    </svg>
  );
});
const IncorrectIcon: React.FC = React.memo(() => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM4.52419 4.52419C4.9705 4.07787 5.69412 4.07787 6.14043 4.52419L7.99898 6.38273L9.85752 4.52419C10.3038 4.07787 11.0275 4.07787 11.4738 4.52419C11.9201 4.9705 11.9201 5.69412 11.4738 6.14043L9.61522 7.99898L11.4738 9.85752C11.9201 10.3038 11.9201 11.0275 11.4738 11.4738C11.0275 11.9201 10.3038 11.9201 9.85752 11.4738L7.99898 9.61522L6.14043 11.4738C5.69412 11.9201 4.9705 11.9201 4.52419 11.4738C4.07788 11.0275 4.07788 10.3038 4.52419 9.85752L6.38273 7.99898L4.52419 6.14043C4.07787 5.69412 4.07787 4.9705 4.52419 4.52419Z"
        fill="white"
      />
    </svg>
  );
});
