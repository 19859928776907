import React from "react";
import cn from "classnames";
import styles from "./Skeleton.module.scss";

export const Skeleton: React.FC = React.memo(() => {
  return (
    <div className={styles.Skeleton}>
      <div className={cn(styles.Skeleton__Item, styles.Skeleton__Item_1)} />
      <div className={cn(styles.Skeleton__Item, styles.Skeleton__Item_2)} />
      <div className={styles.Skeleton__Item} />
      <div className={styles.Skeleton__Item} />
      <div className={styles.Skeleton__Item} />
      <div className={styles.Skeleton__Item} />
      <div className={styles.Skeleton__Item} />
      <div className={cn(styles.Skeleton__Item, styles.Skeleton__Item_3)} />
    </div>
  );
});

Skeleton.displayName = "Skeleton";
