import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ListLayout } from "../../components/ListLayout/ListLayout";
import { WrappedLoader } from "../../components/_base/Loader/Loader";
import { QuizzesList } from "../../components/QuizzesList/QuizzesList";
import { UserContext } from "../../providers/UserProvider";
import { PATH } from "../../routes";
import { SessionStorageKeys, Storage } from "../../utils/storage";
import styles from "./MyQuizzesListScreen.module.scss";

export const MyQuizzesListScreen: React.FC = React.memo(() => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { fbUser, ownUser } = useContext(UserContext);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const onBackClick = useCallback(() => {
    navigate(PATH.root);
  }, [navigate]);

  useEffect(() => {
    if (!fbUser) {
      navigate(PATH.root, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (scrollRef.current) {
      const scrollTop = parseInt(
        Storage.getItem(
          window.sessionStorage,
          SessionStorageKeys.MY_LIST_SCROLL
        ) || ""
      );
      if (!Number.isNaN(scrollTop)) {
        scrollRef.current.scrollTop = scrollTop;
      }
    }

    return () => {
      if (scrollRef.current) {
        Storage.setItem(
          window.sessionStorage,
          SessionStorageKeys.MY_LIST_SCROLL,
          // eslint-disable-next-line react-hooks/exhaustive-deps
          scrollRef.current.scrollTop.toString()
        );
      }
    };
  }, []);

  return (
    <ListLayout position="static" scrollRef={scrollRef} onBack={onBackClick}>
      <div className={styles.MyQuizzesListScreen__Title}>
        {t("quizzesYouCreated")}
      </div>
      <div className={styles.MyQuizzesListScreen__Desc}>
        {t("shareQuizzes")}
      </div>
      {ownUser.data ? (
        <QuizzesList
          items={ownUser.data.createdQuizzes}
          isShowSharingButton={true}
          userId={fbUser?.uid}
        />
      ) : (
        <WrappedLoader />
      )}
    </ListLayout>
  );
});
MyQuizzesListScreen.displayName = "QuizzesListScreen";
