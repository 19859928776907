import gameanalytics from "gameanalytics"
import info from '../../package.json'

const GAME_ANALYTICS_KEY = "8c07f41694298924f25df8ecc662a0e6";
const GAME_ANALYTICS_SECRET_KEY = "4e9e6ab9e945221748484b19d3a5a18fd64871a4";

export const initAnalytics = () => {
    //gameanalytics.GameAnalytics.setEnabledInfoLog(true);
    //gameanalytics.GameAnalytics.setEnabledVerboseLog(true);

    gameanalytics.GameAnalytics.configureBuild(info.version);
    gameanalytics.GameAnalytics.initialize(GAME_ANALYTICS_KEY, GAME_ANALYTICS_SECRET_KEY);
};
