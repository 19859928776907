import cn from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../_base/Text/Text";
import { QuizMode } from "../../types";
import Image from "../_base/Image/Image";
import quizModeDemoImg from "../../assets/images/quiz_mode2.png";
import quizModeCompetitionImg from "../../assets/images/quiz_mode1.png";
import styles from "./QuizModeSelector.module.scss";

interface IPropTypes {
  mode: QuizMode | null;
  onChange: (mode: QuizMode) => void;
}

export const QuizModeSelector: React.FC<IPropTypes> = React.memo(
  ({ mode, onChange }) => {
    const { t } = useTranslation();

    return (
      <div className={styles.QuizModeSelector}>
        <Selector mode={mode} onChange={onChange} />
        <Text fontSize={12} textAlign="center" color="rgba(255, 255, 255, 0.5)">
          {mode === QuizMode.competition
            ? t("challengeModeInfo")
            : t("educationModeInfo")}
        </Text>
      </div>
    );
  }
);

QuizModeSelector.displayName = "QuizModeSelector";

const Selector: React.FC<IPropTypes> = React.memo(({ mode, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.Selector}>
      <div
        className={cn(styles.Selector__Item, {
          [styles.Selector__Item_State_Selected]: mode === QuizMode.competition,
          [styles.Selector__Item_State_Unselected]:
            mode && mode !== QuizMode.competition,
        })}
        onClick={() => onChange(QuizMode.competition)}
      >
        <div className={styles.Selector__ItemImage}>
          <Image src={quizModeCompetitionImg} alt="competition-mode" />
        </div>
        <span className={styles.Selector__ItemLabel}>{t("sporty")}</span>
      </div>
      <div
        className={cn(styles.Selector__Item, {
          [styles.Selector__Item_State_Selected]: mode === QuizMode.knowledge,
          [styles.Selector__Item_State_Unselected]:
            mode && mode !== QuizMode.knowledge,
        })}
        onClick={() => onChange(QuizMode.knowledge)}
      >
        <div className={styles.Selector__ItemImage}>
          <Image src={quizModeDemoImg} alt="knowledge-mode" />
        </div>
        <span className={styles.Selector__ItemLabel}>{t("training")}</span>
      </div>
    </div>
  );
});
